var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.rootComponent, { tag: "component" }, [
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-4" },
      [
        _vm.showBrowserWarning && !_vm.printMode
          ? _c(
              "vs-alert",
              {
                staticClass: "mt-5 col-span-12 text-warning mb-4",
                attrs: {
                  title: _vm.$t("alerta"),
                  color: "rgb(231, 154, 23)",
                  active: "true",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("melhor-visualizacao-e-impressao-na-versa")) +
                    " "
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-span-12 page mx-auto mt-4" },
          [
            _c("full-questionnaire-blank", {
              staticClass: "mb-2",
              attrs: { questionnaire_data: _vm.questionnaire },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }