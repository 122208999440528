<template>
  <vx-card class="border-grey border-solid border p-0">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <vx-card class="mx-0 px-0 shadow-none">
          <div class="grid gap-2">
            <div v-if="questionnaireName" class="flex flex-col row-start-1 col-span-3">
              <label class="font-bold">{{ $t('Questionnaire') }}</label>
              <span>{{questionnaireName}}</span>
            </div>
            <div v-if="questionnaireOwner" class="flex flex-col row-start-2">
              <label class="font-bold">{{ $t('owner_by') }}</label>
              <span>{{questionnaireOwner}}</span>
            </div>
            <div v-if="questionnaireCreatedAt" class="flex flex-col row-start-2">
              <label class="font-bold">{{ $t('criado-em') }}</label>
              <span>{{questionnaireCreatedAt}}</span>
            </div>
            <div v-if="questionnairePublishedAt" class="flex flex-col row-start-2">
              <label class="font-bold">{{ $t('Release_Date') }}</label>
              <span>{{questionnairePublishedAt}}</span>
            </div>
            <div v-if="questionnaireTotalScore" class="flex flex-col row-start-2">
              <label class="font-bold">{{ $t('pontuacao-total') }}</label>
              <span class="text-center">{{questionnaireTotalScore}}</span>
            </div>
            <div v-if="questionnaireStartAt || questionnaireFinishAt" class="flex flex-col col-span-4 row-start-3">
              <label class="font-bold">{{ $t('fields.term') }}</label>


              <span class="flex items-center" v-if="questionnaireStartAt !== null || questionnaireFinishAt !== null">
                <span>
                  <span v-if="questionnaireStartAt !== null">
                    {{ $t('inicio-as') }} {{ questionnaireStartAt }}
                  </span>
                  <span v-if="questionnaireFinishAt !== null">
                    <span v-if="questionnaireStartAt !== null"> - </span> {{ $t('termino-as') }}
                      {{ questionnaireFinishAt }}
                  </span>
                </span>
              </span>
            </div>
            <!-- description -->
            <div v-if="description" class="flex flex-col col-span-4 row-start-4">
              <label class="font-bold">{{ $t('informacoes-gerais') }}</label>
              <div v-if="description" class="editor-content ck-content" id="descriptionElement" v-html-safe="description"></div>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="col-span-12">
        <div>
          <div class="px-4" v-for="(field, index) in answeredQuestionnaireFields" :key="field.id">
            <vx-card :class="getRowClass(field)">
              <div class="grid grid-rows-1" v-if="_.get(field, 'type', null) !== 'section'">
                <div class="grid grid-cols-2">
                  <div class="float-left">
                    <span style="font-size: 12px">{{ scoreSet(field) }}</span>
                  </div>
                </div>
              </div>
              <div>
                <SectionAnswer
                  :item="{ data: { title: field.data.title } }"
                  v-if="field.type === 'section'">
                </SectionAnswer>
                <SimpleTextShow
                  :item="field"
                  :showAnswerSection="false"
                  :hide_score="false"
                  :showQuestionNumber="true"
                  :showQuestionScore="true"
                  :index="questionNumber(index)"
                  v-if="field.type === 'simple_text'">
                </SimpleTextShow>
                <MultipleChoiceShow
                  :item="field"
                  :showQuestionNumber="true"
                  :showQuestionScore="true"
                  :index="questionNumber(index)"
                  v-if="field.type === 'multiple_choice'">
                </MultipleChoiceShow>
                <CheckBoxShow
                  :item="field"
                  :showQuestionNumber="true"
                  :showQuestionScore="true"
                  :index="questionNumber(index)"
                  v-if="field.type === 'check_box'">
                </CheckBoxShow>
                <GapShow
                  :item="field"
                  :showQuestionNumber="true"
                  :showQuestionScore="true"
                  :index="questionNumber(index)"
                  v-if="field.type === 'gap'">
                </GapShow>
                <EssayShow
                  :item="field"
                  :showAnswerSection="false"
                  :hide_score="false"
                  :showQuestionNumber="true"
                  :showQuestionScore="true"
                  :index="questionNumber(index)"
                  v-if="field.type === 'essay'">
                </EssayShow>
              </div>
            </vx-card>
          </div>
          <div class="vx-col grid w-full mt-6">
            <div v-if="organizationLogo" class="flex items-stretch mx-auto place-self-center">
              <div class="flex items-center">
                <label class="text-black text-sm mr-2 text-center">{{
                  $t('made_by')
                }}</label>
              </div>
              <img
                :src="organizationLogo"
                style="max-width: 100px"
              />
            </div>
            <div class="flex items-stretch mx-auto place-self-center">
              <label class="text-primary text-sm mr-2">{{
                $t('Powered_by')
              }}</label>
              <a href="https://educatena.com.br" target="_blank">
                <img
                  src="@/assets/images/logo/logo.png"
                  style="max-width: 100px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import SectionAnswer from '../questionnaires/answers/questions/SectionAnswer'
import SimpleTextShow from '../questionnaires/answers/questions/show/SimpleTextShow'
import EssayShow from '../questionnaires/answers/questions/show/EssayShow'
import MultipleChoiceShow from '../questionnaires/answers/questions/show/MultipleChoiceShow'
import CheckBoxShow from '../questionnaires/answers/questions/show/CheckBoxShow'
import GapShow from '../questionnaires/answers/questions/show/GapShow'
import { getQuestion } from '@/util/QuestionnaireUtil'

import OrganizationService from '@/services/api/OrganizationService'

export default {
  components: {
    SectionAnswer,
    SimpleTextShow,
    MultipleChoiceShow,
    CheckBoxShow,
    GapShow,
    EssayShow
  },
  props : {
    answer: {
      type: Object,
      default: null
    },
    questionnaire_data: {
      type: Object,
      default: null
    },
    questionnaire_short_url: {
      type: String,
      default: null
    },
    logo: {
      type: String,
      default: null
    }

  },

  data: () => ({

  }),

  computed: {
    organizationLogo(){
      return this._.get(this.questionnaire_data, 'organization_logo', null)
    },
    questionnaireOwner() {
      return this._.get(this.questionnaire_data, 'owner_formatted', null)
    },
    questionnaireCreatedAt() {
      return this._.get(this.questionnaire_data, 'created_at_formatted', null)
    },
    questionnairePublishedAt() {
      return this._.get(this.questionnaire_data, 'published_at_formatted', null)
    },
    questionnaireStartAt() {
      return this._.get(this.questionnaire_data, 'start_at_formatted', '--')
    },
    questionnaireFinishAt() {
      return this._.get(this.questionnaire_data, 'finish_at_formatted', '--')
    },
    questionnaireName() {
      return this._.get(this.questionnaire_data || {}, 'name', null)
    },
    description(){
      return this._.get(this.questionnaire_data, 'description', '')
    },
    questionnaireTotalScore() {
      return this._.get(this.questionnaire_data, 'total_score', '0,0')
    },
    answeredQuestionnaireFields() {
      const data = this._.get(this.questionnaire_data, 'data', null)
      if (data) {
        try {
          const parsedData = JSON.parse(data)
          const questions = parsedData['fields']
          return (this._.isArray(questions) && questions) || null
        } catch (error) {
          if (error instanceof SyntaxError) {
            return null
          } else {
            console.error(error)
            return null
          }
        }
      } else {
        return null
      }
    }
  },
  methods : {
    scoreSet(field) {
      if (!this.isEmpty(field.max_score)) {
        const scoreset = parseFloat(field.max_score).toFixed(2).replace('.', ',')
        return this.$t('valor-scoreset', [scoreset])
      }
      return ''
    },
    formmatedScore(score) {
      return !this.isEmpty(score) ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    },
    getRowClass(data) {
      const base = 'mb-2'
      const questionId = this._.get(data, 'id', null)

      if (questionId) {
        const question = getQuestion(questionId, this.answeredQuestionnaireFields, {})
        const isInvalidated = this._.get(question, 'invalidated.justification', false)
        if (isInvalidated !== false) {
          return `${base} invalidated-item`
        }
      }

      return base
    },
    sections(questions) {
      const fields = questions
      let sectionAccumulator = []
      const sections = []

      const endSection = () => {
        sections.push(sectionAccumulator)
        sectionAccumulator = []
      }

      fields.forEach((question, position) => {
        const type = this._.get(question, 'type', null) || null

        if (type === 'section' && position !== 0) {
          /**
           * We only end sections on questions of type section,
           * never on the first question.
           */
          endSection()
        }

        sectionAccumulator.push(question)

        if (position === fields.length - 1) {
          /**
           * If we are at the last question and there was no section
           * to finish the currently accumulating section,
           * we end said section.
           */
          endSection()
        }
      })

      return sections
    },
    questionsQuantity(questions) {
      questions = questions ? questions.filter(question => {
        return question.type !== 'section'
      }) : []
      return questions.length
    },
    sectionQuantity(questions) {
      questions = questions
        ? questions.filter(question => {
          return question.type === 'section'
        })
        : []
      return questions.length
    },
    countQuestionsBeforeSection(sectionIndex) {
      let qtd = 0
      for (let index = 0; index < sectionIndex; index++) {
        const section = this.sections[index]
        qtd = qtd + this.questionsQuantity(section)
      }
      return qtd + 1
    },
    countQuestionsBeforePresentedField(questions, fieldIndex) {
      let qtd = 0
      for (let index = 0; index <= fieldIndex; index++) {
        const question = questions[index]
        if (question) {
          qtd += question.type !== 'section' ? 1 : 0
        }
      }
      return qtd
    },
    questionNumber(index) {
      return this.countQuestionsBeforePresentedField(this.answeredQuestionnaireFields, index)
    },
  },
  beforeMount() {

  }
}
</script>

<style lang="scss" scoped>
  .vx-card /deep/ .vx-card__body {
    @apply px-2;
    @apply py-2;
    // padding: 0px;
  }
</style>
<style lang="scss">
  @media print {
    .vx-card__body {
      page-break-inside: avoid;
    }
    .choose-button .vs-icon{
      font-size: 20px !important;
    }
    .check-current{
      margin-top: 4px !important;
      margin-right: 4px !important;
    }
    html{
      font-size: 12px !important;
    }
    h1, h2, h3, h4, h5{
      font-weight: 300;
    }
    ul, img {
      page-break-inside: avoid;
    }

  }
  @media print and (color) {
    * {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
  }
</style>
